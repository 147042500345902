import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react"

const headers = [
  "Symbol",
  "Ticket",
  "Time",
  "Type",
  "Volume",
  "Price",
  "S/L",
  "T/P",
  "Price",
  "Swap",
  "Profit",
  "Comment",
]

const data = [
  [
    "USDSEK",
    "15008021606",
    "03.05 12:15:00",
    "buy",
    "0.10",
    "10.38868",
    "",
    "",
    "10.38868",
    "",
    "1.02",
    "",
  ],
  [
    "NZUSD",
    "15008021606",
    "03.05 12:15:00",
    "buy",
    "0.10",
    "10.38868",
    "",
    "",
    "10.38868",
    "",
    "1.02",
    "",
  ],
]

const OrdersTable = () => {
  return (
    <>
      <TableContainer>
        <Table variant="simple" fontSize="sm">
          <Thead>
            <Tr>
              {headers.map((_h) => (
                <Th p={2} key={_h} textTransform="unset">
                  {_h}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((_d) => (
              <Tr>
                {_d.map((d) => (
                  <Td p={2}>{d}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              {headers.map((_h) => (
                <Th p={2} key={_h} textTransform="unset">
                  {_h}
                </Th>
              ))}
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  )
}

export default OrdersTable
