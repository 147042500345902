import { Text } from "@chakra-ui/react";
import React from "react";

function CustomText({ children, size, color }: any) {
  return (
    <>
      <Text fontSize={size ? size : "14px"} color={color ? color : "black"}>
        {children}
      </Text>
    </>
  );
}

export default CustomText;
