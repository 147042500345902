import React from "react"
import logo from "./logo.svg"
import "./App.css"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "./theme"
import DashboardScreen from "./screens/dashboard"

function App() {
  return (
    <ChakraProvider theme={theme}>
      <DashboardScreen />
    </ChakraProvider>
  )
}

export default App
