import Dashboard from "../components/pages/dashboard"

const DashboardScreen = () => {
  return (
    <>
      <Dashboard />
    </>
  )
}

export default DashboardScreen
