import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Input,
  Flex,
  Text,
} from "@chakra-ui/react"

import { Box } from "@chakra-ui/react"
import SearchComponent from "../../utilty/SearchComponent"
import ArrowUpDownSymbol from "../../utilty/ArrowUpDownSymbol"
import { useState } from "react"
import CustomTable from "../../utilty/CustomTable"
import ArrowUpDownDot from "../../utilty/ArrowUpDownDot"
import { useSymbolStore } from "../../../store/useSymbolStore"
import { DataTable } from "../../utilty/Table"
import { getColor } from "../../../lib/helper"
const SymbolCustomText = ({ children, value, threshold }: any) => {
  return (
    <Text
      textAlign={"right"}
      fontWeight={400}
      color={getColor(value, threshold)}
    >
      {children}
    </Text>
  )
}

const SearchSymbol = () => {
  const symbolData = useSymbolStore((state: any) => state.symbols)
  const activeSymbol = useSymbolStore((state: any) => state.activeSymbol)
  const setActiveSymbol = useSymbolStore((state: any) => state.setActiveSymbol)
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const columns = [
    {
      Header: "Symbol",
      accessor: "symbol",

      CellComponent: ({ rowData }: any) => {
        return (
          <ArrowUpDownDot
            text={rowData.symbol}
            bid={rowData.bid}
            ask={rowData.ask}
          />
        )
      },
    },
    {
      Header: "Bid",
      accessor: "bid",
      CellComponent: ({ rowData }: any) => {
        return (
          <SymbolCustomText
            textAlign={"right"}
            value={rowData.bid}
            threshold={10}
          >
            {rowData.bid}
          </SymbolCustomText>
        )
      },
    },
    {
      Header: "Ask",
      accessor: "ask",
      CellComponent: ({ rowData }: any) => {
        return (
          <SymbolCustomText value={rowData.ask} threshold={10}>
            {rowData.ask}
          </SymbolCustomText>
        )
      },
    },
    {
      Header: "Daily Change",
      accessor: "dailychange",
      CellComponent: ({ rowData }: any) => {
        return (
          <SymbolCustomText value={rowData.dailychange} threshold={10}>
            {rowData.dailychange}
          </SymbolCustomText>
        )
      },
    },
  ]

  const onHandleRowData = (ctx: any) => {
    console.log(ctx)
  }

  return (
    <>
      <Box
        width="100%"
        height="100%"
        bg="white"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <SearchComponent
          onClick={() => setIsSearchFocus(!isSearchFocus)}
          isFocuse={isSearchFocus}
          onSearchCancel={() => setIsSearchFocus(false)}
        />

        <Box>
          <CustomTable
            columns={columns}
            data={symbolData}
            onHandleRowData={(sy: any) => {
              console.log("====ROW DATA====", sy, setActiveSymbol)

              setActiveSymbol(sy)
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default SearchSymbol

const headers = ["Symbol", "Bid", "Ask", "Daily Change"]

const data = [
  ["EURUSD", "1.08490", "1.08492", "0.05%"],
  ["GBPUSD", "1.08490", "1.08492", "0.05%"],
  ["USDJPY", "1.08490", "1.08492", "0.05%"],
]

const SymbolsTable = () => {
  return (
    <>
      <TableContainer>
        <Table variant="simple" fontSize="sm">
          <Thead>
            <Tr>
              {headers.map((_h, index) => (
                <Th
                  py={1}
                  px={3}
                  key={index}
                  textTransform="unset"
                  fontSize={"12px"}
                  fontWeight={"400"}
                  color={"black"}
                  borderRightWidth={"1px"}
                  borderBottomWidth={0}
                  borderTopWidth={"1px"}
                  borderRightColor={"gray.50"}
                  textAlign={index == 0 ? "left" : "right"}
                >
                  {/* <ArrowUpDownSymbol text={_h} /> */}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((_d, index) => (
              <Tr key={index}>
                {_d.map((d) => (
                  <Td p={2} borderBottomWidth={0}>
                    {d}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
