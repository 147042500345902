//import create from "zustand";
import { create } from "zustand"
import symbolDummyData from "../json/symbolData.json"

interface ISymbol {
  symbol: string
  bid: string
  ask: string
  dailychange: string
  key: string
}

export const useSymbolStore = create((set) => ({
  symbols: symbolDummyData,
  activeSymbol: symbolDummyData[0],
  setActiveSymbol: (sy: ISymbol) =>
    set((state: any) => ({
      ...state,
      activeSymbol: sy,
    })),
}))
