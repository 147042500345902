// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from "react"
import { useSymbolStore } from "../../../store/useSymbolStore"
import {
  useChartType,
  useTimeType,
  useVolumeType,
} from "../../../store/useChartType"

function TradingViewWidget() {
  const container = useRef<any>(null)
  const activeSymbol = useSymbolStore((state: any) => state.activeSymbol)
  const volumeType: any = useVolumeType()
  const timeType: any = useTimeType()
  const chartType: any = useChartType((state: any) => state.chart)

  useEffect(() => {
    console.log("ACTIVBE SymboL")
    console.log(activeSymbol)

    const script = document.createElement("script")
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js"
    script.type = "text/javascript"
    script.async = true
    script.innerHTML = `
        {
          "hide_top_toolbar": true,
            "autosize": true,
            "symbol": "${activeSymbol?.key}",
            "interval": "${timeType?.time}",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "${chartType}",
            "locale": "in",
            "enable_publishing": false,
            "gridColor": "rgba(255, 255, 0, 0.06)",
            "hide_legend": true,
            "withdateranges": true,
            "hide_side_toolbar": false,
            "calendar": false,
            "hide_volume": ${volumeType?.hide_volume},
            "support_host": "https://www.tradingview.com"
        }`

    console.log(script.innerHTML)
    // Clear previous script before appending new one
    if (container?.current) {
      container.current.innerHTML = ""
    }

    container.current.appendChild(script)
  }, [activeSymbol, volumeType?.hide_volume, timeType?.time, chartType])

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100%)", width: "100%" }}
      ></div>
    </div>
  )
}

export default memo(TradingViewWidget)
