import { Box, Divider, Flex, IconButton, Text } from "@chakra-ui/react"
import { MdMenu, MdBarChart, MdCandlestickChart } from "react-icons/md"
import { FaChartArea } from "react-icons/fa"
import {
  useChartType,
  useTimeType,
  useVolumeType,
} from "../../../store/useChartType"
import { MdShowChart, MdSsidChart, MdFullscreen } from "react-icons/md"

import _ from "lodash"
import { useState } from "react"

const CHART_TYPES = [
  {
    icon: <MdBarChart />,
    key: 0,
  },
  {
    icon: <MdCandlestickChart />,
    key: 1,
  },
  {
    icon: <FaChartArea />,
    key: 3,
  },
]

const VOLUME_TYPES = [
  {
    icon: <MdShowChart />,
    key: true,
  },
  {
    icon: <MdSsidChart />,
    key: false,
  },
]

const DATE_TIME = [
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        M1
      </Text>
    ),
    key: 1,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        M5
      </Text>
    ),
    key: 5,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        M15
      </Text>
    ),
    key: 15,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        M30
      </Text>
    ),
    key: 30,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        H1
      </Text>
    ),
    key: 60,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        H4
      </Text>
    ),
    key: 240,
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        D1
      </Text>
    ),
    key: "D",
  },
  {
    icon: (
      <Text fontWeight={400} fontSize="sm">
        W1
      </Text>
    ),
    key: "W",
  },
]

const TopBar = () => {
  const chartType: any = useChartType()
  const volumeType: any = useVolumeType()
  const timeType: any = useTimeType()
  const [isFullScreen, setIsFullScreen] = useState(false)

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Request full screen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }

    // Toggle the state
    setIsFullScreen(!isFullScreen)
  }

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Box p={1} borderRight="1px solid" borderRightColor="gray.200">
          <IconButton aria-label="" icon={<MdMenu />} variant="ghost" />
        </Box>

        <Flex align="center" display="inline-flex" p={1}>
          {_.map(CHART_TYPES, (t) => {
            return (
              <IconButton
                key={t?.key}
                colorScheme="blue"
                _hover={{
                  color: "blue.200",
                }}
                color={chartType?.chart == t?.key ? "blue.500" : "gray.400"}
                aria-label=""
                icon={t?.icon}
                variant="ghost"
                onClick={() => {
                  if (chartType?.setChartType) {
                    chartType?.setChartType(t?.key)
                  }
                }}
              />
            )
          })}
          <Divider width="1px" height="32px" orientation="vertical" />

          {_.map(VOLUME_TYPES, (t) => {
            return (
              <IconButton
                colorScheme="blue"
                _hover={{
                  color: "blue.200",
                }}
                color={
                  volumeType?.hide_volume == t?.key ? "blue.500" : "gray.400"
                }
                aria-label=""
                icon={t?.icon}
                variant="ghost"
                onClick={() => {
                  if (volumeType?.setHideVolume) {
                    volumeType?.setHideVolume(t?.key)
                  }
                }}
              />
            )
          })}
          <Divider width="1px" height="32px" orientation="vertical" />

          {_.map(DATE_TIME, (t) => {
            return (
              <IconButton
                colorScheme="blue"
                _hover={{
                  color: "blue.200",
                }}
                color={timeType?.time == t?.key ? "blue.500" : "gray.400"}
                aria-label=""
                icon={t?.icon}
                variant="ghost"
                onClick={() => {
                  if (timeType?.setTime) {
                    timeType?.setTime(t?.key)
                  }
                }}
              />
            )
          })}
          <Flex></Flex>
        </Flex>

        <Flex
          align="center"
          p={1}
          borderRight="1px solid"
          borderRightColor="gray.200"
          ml="auto"
          display="inline-flex"
        >
          <IconButton
            aria-label=""
            icon={<MdFullscreen />}
            variant="ghost"
            onClick={() => {
              toggleFullScreen()
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default TopBar
