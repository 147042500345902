import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import CustomText from "../element/CustomText";

interface IArrowUpDownSymbol {
  isActive: boolean;
  direction: string | null;
  text: string;
  isRightArrow?: boolean;
}

function ArrowUpDownSymbol({
  isActive,
  direction,
  text,
  isRightArrow = false,
}: IArrowUpDownSymbol) {
  const getArrowIcon = () => {
    if (!isActive) return null;
    return direction === "ascending" ? (
      <RiArrowUpSFill style={{ color: "#0B71F3" }} size={"18px"} />
    ) : direction === "descending" ? (
      <RiArrowDownSFill style={{ color: "#D80000" }} size={"18px"} />
    ) : null;
  };

  return (
    <>
      <Flex alignItems={"center"} cursor={"pointer"}>
        {!isRightArrow && getArrowIcon()}
        <Box flex={1}>
          <CustomText size="12px">{text}</CustomText>
        </Box>
        {isRightArrow && getArrowIcon()}
      </Flex>
    </>
  );
}

export default ArrowUpDownSymbol;
