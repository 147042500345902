import React, { useState } from "react"
import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from "@chakra-ui/react"
import ArrowUpDownSymbol from "./ArrowUpDownSymbol"

function CustomTable({ columns, data, onHandleRowData }: any) {
  const [activeRow, setActiveRow] = useState(0)
  const [sortConfig, setSortConfig] = useState<any>({
    key: null,
    direction: null,
  })

  const toggleRow = (rowIndex: any, rowData: any) => {
    setActiveRow((prevActiveRow) =>
      prevActiveRow === rowIndex ? prevActiveRow : rowIndex
    )
    onHandleRowData({ ...rowData })
  }

  const requestSort = (key: any) => {
    let direction: any = "ascending"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending"
    } else if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null // For normal sorting
    }
    setSortConfig({ key, direction })
  }

  const sortedData = () => {
    const sortableData = [...data]
    if (sortConfig && sortConfig.direction !== null) {
      sortableData.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return sortableData
  }

  const renderRows = () => {
    const sortedRows = sortedData()
    return sortedRows.map((row: any, rowIndex: any) => (
      <Tr
        key={rowIndex}
        onClick={() => toggleRow(rowIndex, row)}
        //@ts-ignore
        bg={activeRow === rowIndex ? "skyBlue" : "white"}
        cursor="pointer"
      >
        {columns.map((column: any, colIndex: any) => (
          <Td
            borderBottomWidth={0}
            key={colIndex}
            isNumeric={column.isNumeric}
            p={2}
            {...column.tdProps}
          >
            {column.CellComponent ? (
              <column.CellComponent rowData={row} />
            ) : (
              row[column.accessor]
            )}
          </Td>
        ))}
      </Tr>
    ))
  }

  return (
    <>
      <TableContainer>
        <Table variant="simple" fontSize="sm">
          <Thead>
            <Tr>
              {columns.map((column: any, index: any) => (
                <Th
                  key={index}
                  py={1}
                  px={3}
                  textTransform="unset"
                  fontSize={"12px"}
                  fontWeight={"400"}
                  color={"black"}
                  borderRightWidth={"1px"}
                  borderBottomWidth={0}
                  borderTopWidth={"1px"}
                  borderRightColor={"gray.100"}
                  textAlign={index == 0 ? "left" : "right"}
                  onClick={() => requestSort(column.accessor)}
                >
                  <ArrowUpDownSymbol
                    isRightArrow={index === 0 ? true : false}
                    text={column.Header}
                    isActive={sortConfig && sortConfig.key === column.accessor}
                    direction={sortConfig && sortConfig.direction}
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>{renderRows()}</Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CustomTable
