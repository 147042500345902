import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { RxCross1 } from "react-icons/rx";
import { GoSearch } from "react-icons/go";
import CustomText from "../element/CustomText";
interface IsearchComponent {
  onSearchCancel?: any;
  isFocuse?: boolean;
  onClick?: any;
  onChange?: any;
  isActive?: boolean;
}

function SearchComponent({
  onSearchCancel,
  isFocuse,
  onChange,
  onClick,
  isActive,
}: IsearchComponent) {
  return (
    <Box position={"relative"}>
      <Flex alignItems={"center"}>
        <Box p={"4"}>
          <GoSearch width={"24px"} height={"24px"} />
        </Box>
        <Input
          onClick={onClick}
          onChange={onChange}
          variant="unstyled"
          placeholder="Search Symbol"
          _placeholder={{ fontWeight: "400" }}
        />
        {isFocuse && (
          <Box cursor={"pointer"} p={"4"} onClick={onSearchCancel}>
            <RxCross1 />
          </Box>
        )}
      </Flex>
      {isFocuse && (
        <Box
          position={"absolute"}
          left={0}
          right={0}
          py={"4"}
          bg={"white"}
          height={"300px"}
        >
          <Flex
            px={"4"}
            _hover={{ bg: "skyBlue" }}
            cursor={"pointer"}
            justifyContent={"space-between"}
            alignItems={"center"}
            py={"2"}
            bg={isActive ? "skyBlue" : "white"}
          >
            <CustomText fontSize={"14px"}>Forex</CustomText>
            <CustomText color="gray.200">0/123</CustomText>
          </Flex>
        </Box>
      )}
    </Box>
  );
}

export default SearchComponent;
