import { Box, Flex } from "@chakra-ui/react"
import TradingViewWidget from "./chart-view"
import SearchSymbol from "./search-symbol"
import OrdersTable from "./order-tables"
import TopBar from "./top-bar"

const Dashboard = () => {
  return (
    <>
      <Flex
        width="100vw"
        height="100vh"
        direction="column"
        wrap="wrap"
        position="fixed"
        top={0}
        left={0}
        bg="white"
      >
        <TopBar />
        <Flex width="100vw" flex={1} direction="row">
          <Box flex={1}>
            <TradingViewWidget />
          </Box>

          <Box
            width="30%"
            maxWidth="340px"
            height="100%"
            overflowY="scroll"
            bg="white"
          >
            <SearchSymbol />
          </Box>
        </Flex>
        <Box width="100vw" height="225px" overflowY="scroll" bg="white">
          <OrdersTable />
        </Box>
      </Flex>
    </>
  )
}

export default Dashboard
