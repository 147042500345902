import { create } from "zustand"

export const useChartType = create((set) => ({
  chart: 0,
  setChartType: (type: number) =>
    set((state: { chart: number }) => ({
      ...state,
      chart: type,
    })),
}))

export const useVolumeType = create((set) => ({
  hide_volume: true,
  setHideVolume: (type: boolean) =>
    set((state: { type: boolean }) => ({
      ...state,
      hide_volume: type,
    })),
}))

export const useTimeType = create((set) => ({
  time: 1,
  setTime: (type: string | number) =>
    set((state: { time: string | number }) => ({
      ...state,
      time: type,
    })),
}))

//
