import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { BsDot } from "react-icons/bs";
import CustomText from "../element/CustomText";

interface IArrowUpDownDot {
  bid: number;
  ask: number;
  text: string;
}

function ArrowUpDownDot({ bid, ask, text }: IArrowUpDownDot) {
  return (
    <Flex alignItems="center" cursor="pointer">
      <Box flex={0} mr={1}>
        {bid > ask && (
          <RiArrowUpSFill style={{ color: "#008000" }} size={"18px"} />
        )}
        {bid < ask && (
          <RiArrowDownSFill style={{ color: "#D80000" }} size={"18px"} />
        )}
        {bid === ask && (
          <BsDot style={{ color: "#808080" }} size={"18px"} />
        )}
      </Box>
      <Box flex={1}>
        <CustomText size="14px">{text}</CustomText>
      </Box>
    </Flex>
  );
}

export default ArrowUpDownDot;
